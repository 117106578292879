import React from 'react';
import getWebpVersion from '../functions/getWebpVersion';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultSrc: null,
            webpFailed: false,
            extraClasses: [
                'progressive-load',
                'opacity-0'
            ]
        };

        this.img = React.createRef();
    }

    componentDidMount() {
        this.img.current.onerror = () => {
            this.setState({ webpFailed: true });
        };
    }

    componentDidUpdate(prevProps) {
        const { src } = this.props;

        if (prevProps.src === src) {
            return;
        }

        this.setState(state => {
            const extraClasses = state.extraClasses.slice();
            const classIndex = state.extraClasses.indexOf('opacity-0');

            extraClasses.splice(classIndex, 1);

            return {
                ...state,
                ...{ extraClasses, defaultSrc: src }
            };
        });
    }

    render() {
        const { alt, className } = this.props;
        const { defaultSrc, extraClasses, webpFailed } = this.state;
        const classes = `${extraClasses.join(' ')} ${className}`;
        const webpSrc = defaultSrc ?  getWebpVersion(defaultSrc) : null;
        const getImgEl = () => <img ref={this.img} className={classes} src={defaultSrc} alt={alt} loading="lazy" />;

        return (
            <>
                {
                    webpFailed ? getImgEl() : (
                        <picture>
                            <source srcSet={webpSrc} type="image/webp" />
                            {getImgEl()}
                        </picture>
                    )
                }
            </>
        );
    }
}

export default Image;