import React, { Suspense } from 'react';
import { findDOMNode } from 'react-dom';
// import ContactForm from './components/ContactForm';
import Social from './components/Social';
import InfoSection from './components/InfoSection';
import Image from './components/Image';
import infoSectionContent from './data/info-sections';
import about from './data/about';
// import workSamples from './data/work-samples';
import headshot from './images/headshot.png';
import background from './images/bg.jpg'
import '@fortawesome/fontawesome-free/css/all.css';
import getWebpVersion from './functions/getWebpVersion';


const resetImages = () => ({
  headshot: null,
  background: null
});

const ContactForm = React.lazy(() => import('./components/ContactForm'));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggles: {
        mainMenu: false,
        contactForm: false
      },
      triggered: {
        contactForm: false
      },
      profileImageUrl: null,
      githubUrl: null,
      footerOffset: null,
      loaded: false,
      profileScrollBottom: false,
      profileScrollHeight: null,
      profileScrollPosition: null,
      profileScrollView: null,
      imgDelay: 1,
      images: resetImages(),
      socialClasses: ['transition-all', 'py-2', 'py-md-0', 'container', 'px-md-0'],
      socialStyle: { top: 'auto' },
      socialWrapStyle: { minHeight: 'auto' },
      socialTimeout: null
    };

    this.footerRef = React.createRef();
    this.profileRef = React.createRef();
    this.profileBackgroundRef = React.createRef();
    this.headshotPlaceholderRef = React.createRef();
    this.socialRef = React.createRef();
    
    this.toggleOff = this.toggleOff.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.mobileSmoothScroll = this.mobileSmoothScroll.bind(this);
  }

  prepareFixedMenu() {
    
    if (window.innerWidth >= 768) {
      return;
    }

    const { socialClasses } = this.state;
    const socialEl = findDOMNode(this.socialRef.current);

    // scrolling past socials from above
    if (socialEl.getBoundingClientRect().bottom < 0 && socialClasses.indexOf('fixed-top') === -1) {
      this.setState(state => {
        const socialClasses = state.socialClasses.slice();

        socialClasses.push('fixed-top');

        return {...state, ...{
          socialClasses,
          socialStyle: {
            top: `-${socialEl.offsetHeight}px`
          },
          socialWrapStyle: {
            minHeight: `${socialEl.offsetHeight}px`
          }
        }};
      }, () => {
        this.socialTimeout = setTimeout(() => {
          this.setState(state => ({...state, ...{
            socialStyle: {...state.socialStyle, ...{
              top: '0',
              background: 'rgba(255,255,255,0.8)'
            }}
          }}));
        }, 500);
      });
    }

    // scrolling back up to socials from below
    if (socialEl.getBoundingClientRect().top >= 0 && socialClasses.indexOf('fixed-top') !== -1) {

      if (this.socialTimeout !== null) {
        clearTimeout(this.socialTimeout);
        this.socialTimeout = null;
      }

      this.setState(state => {
        const socialClasses = state.socialClasses.slice();

        socialClasses.splice(socialClasses.indexOf('fixed-top'), 1);

        return {...state, ...{
          socialClasses,
          socialStyle: {
            top: 'auto'
          }
        }};
      })
    }
  }

  handleScroll(event, el) {
    let target = event ? event.target : null;

    target = target ? target : el;

    this.prepareFixedMenu();

    if (!(target.classList && 'value' in target.classList && target.classList.value.indexOf('profile-content') !== -1)) {
      return;
    }

    const { scrollHeight, clientHeight } = target;
    const scrollTop = Math.round(target.scrollTop);
    const profileScrollHeight = scrollHeight - clientHeight;
    let profileScrollBottom = false;

    if (((scrollHeight !== clientHeight) && (scrollTop === profileScrollHeight)) || (scrollHeight === clientHeight)) {
      profileScrollBottom = true;
    }

    this.setState({
      profileScrollBottom,
      profileScrollHeight,
      profileScrollView: clientHeight,
      profileScrollPosition: scrollTop
    });
  }

  componentDidMount() {
    window.onload = () => {
      this.setState({
        footerOffset: this.footerRef.current.clientHeight,
        loaded: true
      });
      
      this.handleScroll.call(this, null, this.profileRef.current);
    
      // defer loading of images
      setTimeout(() => {
        let imagesData = { headshot };

        imagesData.background = background;

        this.setState({
          images: {
            ...resetImages(),
            ...imagesData
          }
        });
      }, this.state.imgDelay * 1000);
    };

    if (window.document.readyState === 'complete' && !this.state.loaded) {
      this.setState({ loaded: true });
    }

    window.addEventListener('scroll', this.handleScroll);

    window.addEventListener('resize', () => {
      this.mobileSmoothScroll();
      this.setProfileImageHeight();
      this.handleScroll(null, this.profileRef.current);
    });

    this.mobileSmoothScroll();
    this.setProfileImageHeight();
  }

  mobileSmoothScroll() {
    if (!this.profileBackgroundRef?.current?.style) {
      return;
    }

    this.profileBackgroundRef.current.style.height = `${window.innerHeight}px`;
  }

  setProfileImageHeight() {
    if (!this.headshotPlaceholderRef?.current) {
      return;
    }

    const headshotPlaceholderComputedWidth = window.getComputedStyle(this.headshotPlaceholderRef.current).width;
    this.headshotPlaceholderRef.current.style.height = headshotPlaceholderComputedWidth;
  }

  toggleOff(toggledItem = '', event, callback) {
    const { type } = event;
    let currentTarget = event ? event.currentTarget : null;

    if (!toggledItem) return;

    if (event && type === 'click') event.preventDefault();

    this.setState(state => {
      const newState = {};
      let toggles;
      const triggered = {...newState.triggered};

      if (toggledItem === 'contactForm' && ! triggered[toggledItem]) {
        triggered[toggledItem] = true;
      }

      // close contact form
      if (type === 'click') {
        newState[toggledItem] = currentTarget && currentTarget.classList.contains('close') ? false : !state.toggles[toggledItem];
      }
      
      toggles = { ...state.toggled, ...newState };

      return { toggles, triggered };
    }, callback);
  }

  render() {
    const { profileScrollBottom, profileScrollHeight, profileScrollView, footerOffset, loaded, socialStyle, socialWrapStyle } = this.state;
    const canBeScrolled = profileScrollHeight === 0 && profileScrollView;
    const columns = [];
    const mainBgStyle = { backgroundImage: 'none' };

    if (this.state.images.background) {
      mainBgStyle.opacity = '.5';
      mainBgStyle.backgroundImage = `url(${getWebpVersion(background)}), url(${background})`;
    }

    const filteredContentSections = Object.keys(infoSectionContent).filter(key => key !== 'about-me');

    filteredContentSections.forEach((key, i) => {
      const currentSection = infoSectionContent[key];
      const data = Array.isArray(currentSection) ? currentSection : null;
      const columnKey = i % 2 === 0 ? 0 : 1;

      if (!(columnKey in columns)) {
        columns.push([]);
      }

      columns[columnKey].push(
        <div id={key} key={i} className="col-12 mb-md-2 mb-lg-5 mt-5 mt-sm-4 mt-md-0 px-4">
          <InfoSection index={i} data={data} id={key}>
            {currentSection.content}
          </InfoSection>
          <div className="my-4"></div>
        </div>
      );
    });

    return (
      <div className={'App animate__animated ' + (loaded ? 'animate__fadeIn' : 'invisible')}>

        <main>
          <div className="main-bg transition-all d-none d-md-block" style={mainBgStyle}></div>

          <div className="container">
            <div className="position-fixed fixed-bg">
              <div className="content-wrap container h-100 bg-yellow"></div>
            </div>
            
            <div className="row">
              <div className="col-md-3">
                <div className="profile w-100">
                  <div className="container">
                    <div className="row">

                      <div className="col-md-3 px-md-0">

                        <div ref={this.profileBackgroundRef} className="profile-background d-none d-md-block"></div>

                        <div className="profile-wrapper position-relative overflow-hidden">
                          <div className={'scroll-indicator position-absolute w-100' + (profileScrollBottom ? ' d-none' : '')} style={{ zIndex: 999, bottom: '0', right: '20px', height: '50px' }}>
                            <div className="container h-100 w-100 p-0">
                              <div className="h-100 bg-yellow-to-transparent-top d-none d-md-block"></div>
                            </div>
                          </div>
                          
                          <div ref={this.profileRef} className={'profile-content pt-5 px-lg-4' + (!canBeScrolled ? ' px-md-3' : '')} onScroll={this.handleScroll}>
                            
                              <div ref={this.headshotPlaceholderRef} className="profile-image info-section-entry mb-2 rounded bg-dark my-0 mx-auto">
                                <Image className="profile-image rounded transition-all" src={this.state.images.headshot} style={this.state.images.headshot ? {} : { opacity: '0' } } alt="Kyle Tozer"/>
                            </div>

                            <div className="text-center text-md-left">
                              <h1 className="h3 mt-2 mb-0 mb-md-1">
                                <a className="text-decoration-none" href="/">Kyle Tozer</a>
                              </h1>
                              <h2 className="h6">Web Developer</h2>
                            </div>

                            <InfoSection id="about-me">
                              {about.content}
                            </InfoSection>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9 offset-md-3 mb-5 mb-md-0">
                <div className="row ml-lg-2 pt-md-5 px-md-1 pr-lg-5" style={{ paddingBottom: footerOffset + 'px' }}>

                  <div ref={this.socialRef} className="col-12 mb-5 mt-2 mt-sm-0 px-0 px-md-3" style={socialWrapStyle}>
                    <Social className={this.state.socialClasses.join(' ')} toggleOff={this.toggleOff.bind(this, 'contactForm')} iconStates={{ contact: this.state.toggles.contactForm }} style={socialStyle} />
                  </div>

                  {/* content sections */}
                  {columns.map((item, i) => {
                    return (
                      <div key={i} className="col-12 col-lg-6">
                        <div className={'h-100 ' + (i % 2 === 0 ? 'mr-md-1' : 'ml-md-1')}>
                          <div className="row h-100">
                            {item}
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  {/* <div className="col-12">
                    <InfoSection id="work-samples" data={workSamples}></InfoSection>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {
            this.state.triggered.contactForm && (
              <Suspense fallback={<div>loading...</div>}>
                <ContactForm toggled={this.state.toggles.contactForm} toggleOff={this.toggleOff.bind(this, 'contactForm')} />
              </Suspense>
            )
          }
        </main>

        <footer ref={this.footerRef} className="position-absolute w-100">
          <div className="container">
            <div className="row">
              <div className="col-12 p-0">
                <div className="text-center py-2">
                  &copy; { new Date().getFullYear() } Kyle Tozer
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
